import Bugsnag from '@bugsnag/js';
import { dom, library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import bb from './icons/bb';
import bn from './icons/bn';
import kobo from './icons/kobo';

Bugsnag.start({
    apiKey: window.App.bugsnagKey,
    releaseStage: window.App.env,
    metaData: {
        user: window.App.user,
    },
});

library.add(fas, far, fal, fab, bb, bn, kobo);

dom.watch();
